import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Mapped } from "@/__main__/data-model.mjs";

const mapStatsModel = {
  matchesPlayed: Number,
  roundsPlayed: Number,
  attackingRoundsWon: Number,
  defendingRoundsWon: Number,
  successfulPlants: Mapped({ key: String, value: Number }),
  successfulDefuses: Mapped({ key: String, value: Number }),
} satisfies ModelDefinition;

const model = Mapped({
  key: String,
  value: mapStatsModel,
}) satisfies ModelDefinition;

const MapsStatsModel = createModel({
  payload: model,
}).transform(model, (data) => data.payload);

export type ValorantMapStats = FromModel<typeof model>;

export default MapsStatsModel;
