import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Mapped } from "@/__main__/data-model.mjs";

const weaponStatsModel = {
  kills: Number,
  altKills: Number,
  killDistance: Number,
  damage: Number,
  damageInstances: Number,
  matchesPlayed: Number,
  firstBloods: Number,
  lastKills: Number,
  roundsPlayed: Number,
  hits: {
    headshots: Number,
    bodyshots: Number,
    legshots: Number,
  },
} satisfies ModelDefinition;

const model = Mapped({
  key: String,
  value: weaponStatsModel,
}) satisfies ModelDefinition;

const WeaponStatsModel = createModel({
  payload: model,
}).transform(model, (data) => data.payload);

export type ValorantWeaponStats = FromModel<typeof model>;

export default WeaponStatsModel;
